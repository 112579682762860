import React from 'react';
import {Link, graphql} from 'gatsby';
import {getImage, withArtDirection, StaticImage} from 'gatsby-plugin-image';
// Component imports
import Layout from '@components/structure/Layout.jsx';
import Hero from '@components/content/hero.jsx';
import Anchor from '@components/content/Anchor.jsx';
import Video from '@components/content/video/Video.jsx';
import StickyTray from '@components/content/StickyTray';
import Quote from '@components/content/quote';
import ISI from '@components/content/isi/ISI.jsx';
import ISIHead from '@components/content/isiHead';
// Utility imports
import SEO from '@components/utility/SEO.jsx';

export const Head = () => (
	<>
	<script type='application/ld+json'
	 dangerouslySetInnerHTML={{
        __html: JSON.stringify({ 
			"@context":"http://schema.org",
			"type":"VideoObject",
			"name":"Sharing Your Lupus Nephritis Story | Aurinia Alliance®",
			"description":"Watch real people react to their lupus nephritis diagnosis and discover the common thread between each of their journeys.",
			"thumbnailUrl":"https://www.auriniaalliance.com/images/watch-real-people-stories-poster-pt-1.png",
			"uploadDate":"2023-02-01","embedUrl":"https://www.auriniaalliance.com/videos/the-common-thread-of-lupus-nephritis-part1.mp4",
			"contentUrl":"https://www.auriniaalliance.com/resources",
			"duration":"PT1M34S",
		})
	}}
	/>
	</>
  )

const ProgramResources = ({data}) => {
	const heroImage = withArtDirection(
		getImage(data.heroLarge), [
		{
			media: "(max-width: 600px)",
			image: getImage(data.heroSmall),
		},
		{
			media: "(max-width: 1440px)",
			image: getImage(data.heroMedium),
		},

	])
	const signatureImage = getImage(data.signature);
	const tiffanyPortrait = getImage(data.tiffanyPortrait);
	const nancyPortrait = getImage(data.nancyPortrait);

	return(
	<Layout pageClassName="resources">
		<SEO title='Program Resources | Aurinia Alliance&reg;' description='Find tools and resources to help you better understand and manage your treatment for lupus nephritis. Enroll into the Aurinia Alliance&reg; program to receive educational materials, financial assistance, and more.' />
		<Hero heading={['Resources for people who are',<span key={'nbsp'}>&nbsp;</span>,'living with lupus nephritis']} background={heroImage} signature={signatureImage} signatureDisc={[<p key='name'>Emili Z., RNC-MNN</p>,<p key='ncm'>Nurse Case Manager</p>]}>
			<p>When you're fighting a serious disease every day, finding the time to understand and manage your treatment can feel overwhelming and lonely. Aurinia Alliance<sup>&reg;</sup> is here to help every step of the way, so you can focus on what's more important—you.</p>
			<h2>When you enroll, you'll receive:</h2>
			<ul>
                <li>A Dedicated Nurse Case Manager</li>
				<li>Lupus Nephritis and LUPKYNIS<sup>&reg;</sup> Educational Materials</li>
				<li>Financial Assistance</li>
				<li>A Welcome Kit</li>
			</ul>
		</Hero>
		<section className="welcome-kit cta-row cta-row--white">
			<div className="wrapper">
				<h2>See what's inside the LUPKYNIS<sup>&reg;</sup> Welcome Kit</h2>
				<div className="welcome-kit__img--mobile">
						<StaticImage src="../../images/welcome-kit.png" alt='LUPKYNIS&reg; (voclosporin) Welcome Kit'></StaticImage>
					</div>
				<p className='welcome-kit__description'>Your dedicated Nurse Case Manager will send you a Welcome Kit to help you get started on your treatment journey. Inside you'll find:</p>
				<div className='welcome-kit__container'>
					<div className="welcome-kit__list">
						<ul>
							<li>
								<h3>A Welcome Brochure</h3>
								<p>With helpful information about taking LUPKYNIS and how to get support from Aurinia Alliance.</p>
							</li>
							<li>
								<h3>An Instructional Card</h3>
								<p>To help you open your LUPKYNIS blister packs.</p>
							</li>
							<li>
								<h3>A LUPKYNIS Medication Carrier</h3>
								<p>Designed by someone living with lupus nephritis, so you can stylishly store and carry your medication. It's water resistant and has a pocket to store important information.</p>
							</li>
							<li>
								<h3>An Aurinia Alliance Magnet and Dry Erase Marker</h3>
								<p>Where you can track your daily doses and jot down appointments and reminders.</p>
							</li>
						</ul>
						<Link to='/enroll' className='btn btn--purple'>ENROLL TO GET YOUR KIT</Link>
					</div>
					<div className="welcome-kit__img">
						<StaticImage src="../../images/welcome-kit.png" alt='LUPKYNIS&reg; (voclosporin) Welcome Kit'></StaticImage>
					</div>
				</div>
			</div>
		</section>
		<section className="personalized-support cta-row cta-row--white">
			<div className="wrapper">
				<h2>Get personalized support with a Nurse Case Manager</h2>
				<p className='personalized-support__description'>If you're taking LUPKYNIS, when you enroll in Aurinia Alliance you'll be connected with a Nurse Case Manager. They'll be your dedicated contact throughout your Aurinia treatment journey.</p>
				<p className='personalized-support__heading'>Your Nurse Case Manager will provide resources,&nbsp;including</p>
				<div className='personalized-support__container'>
					<div className="card">
						<img src={'/images/apple-book-icon.svg'} width="101" height="101" alt="" className="card__img"/>
                        <h3>Lupus nephritis educational&nbsp;materials</h3>
                        <p className="card__text">Lupus nephritis is a serious and complex disease—and learning about it can quickly become overwhelming. Wherever you are in your journey, your Nurse Case Manager can provide you with helpful information that is tailored to your specific needs. Here are some materials to get started:</p>
						<a className="card__link" href="/pdfs/lupkynis-consumer-brochure.pdf" target="_blank">Getting Started with LUPKYNIS Brochure (English)</a>
						<a className="card__link" href="/pdfs/lupkynis-consumer-brochure-spanish.pdf" target="_blank">Getting Started with LUPKYNIS Brochure (Español)</a>
						<a className="card__link" href="/pdfs/lupkynis-doctor-discussion-guide.pdf" target="_blank">Doctor Discussion Guide</a>
						<Anchor linkClass="card__link" link="https://www.lupkynis.com/what-is-lupus-nephritis" isExternal={true} requiresExitModal={true} relationship="noopener noreferrer">Find more information about lupus nephritis</Anchor>
					</div>
                    <div className="card">
                        <img src={'/images/financial-icon.svg'} width="101" height="101" alt="" className="card__img"/>
                        <h3>Financial assistance</h3>
                        <p className="card__text">Navigating a treatment plan for a serious condition like lupus nephritis can come with concerns about how to afford treatment. Because every situation is unique, your Nurse Case Manager is available to help you understand your insurance benefits not only when you begin treatment, but also if your insurance plan changes. They can also answer your questions about financial assistance for which you may be eligible.</p>
                    </div>
				</div>
				<p className='personalized-support__heading'>97% of the time*, patients paid less than $10 for their LUPKYNIS prescription.</p>
				<p className='personalized-support__foot-note'>*97% of the shipments of LUPKYNIS delivered cost the patient $10 or less. These are patients on commercial insurance, Medicaid or Medicare from January 2022 to December 2022.</p>
			</div>
		</section>
		<section className="cta-row cta-row--sky-blue">
			<div className="wrapper">
				<h2>Already enrolled?</h2>
				<p>Get in touch with your Nurse Case Manager to access<br className='desktop-only'/> your exclusive resources at 1-833-AURINIA (1-833-287-4642)</p>
				<a className="btn btn--white" href={'tel:1-833-287-4642'}>CALL NOW</a>
			</div>
		</section>
		<Quote portrait={tiffanyPortrait} patientInfo={"Tiffany, started treatment 2021"} position={'quote__container--left'} className={'quote--grey'} backgroundImage={true}>
			<p className='quote-text'>"Aurinia has provided a great deal of support. Just having a source that allowed me to talk freely about what I was experiencing helped me feel validated, and to know I was not alone."</p>
		</Quote>
		<section className="connections cta-row cta-row--white">
			<div className="wrapper">
				<h2>Find new connections in the community</h2>
				<div className='flex-container flex-container--right'>
					<div className="flex-container__content">
						<StaticImage src='../../images/ln-peer-connect.png' alt=''></StaticImage>
					</div>
					<div className="flex-container__content">
						<p><strong>Lupus Nephritis Peer Connect<sup>&trade;</sup></strong> is a mentor program that allows you to speak one-on-one with someone who is also living with lupus nephritis and who has experience taking LUPKYNIS.</p>
						<Anchor linkClass="btn btn--purple" link="https://lnpeerconnect.com/" isExternal={true} requiresExitModal={true} relationship="noopener noreferrer">REQUEST A MENTOR</Anchor>
					</div>
				</div>
				<div className='flex-container flex-container--left'>
					<div className="flex-container__content">
						<Video
							src='/videos/the-common-thread-of-lupus-nephritis-part1.mp4'
							type='video/mp4'
							poster='/images/watch-real-people-stories-poster-pt-1.png'
							title='Watch real people share their stories' controlsList='nodownload'
						/>
					</div>
					<div className="flex-container__content">
						<p><strong>Watch Malisha, Suriya, and Jennifer</strong> share their real stories of living with lupus nephritis—from being diagnosed, to overcoming challenges, and discovering the common thread between them.</p>
						<Anchor linkClass="btn btn--purple" link="https://www.lupkynis.com/what-is-lupus-nephritis" isExternal={true} requiresExitModal={true} relationship="noopener noreferrer">LEARN MORE</Anchor>
					</div>
				</div>
			</div>
		</section>
		<Quote portrait={nancyPortrait} patientInfo={"Nancy, started treatment in 2021, pictured with her care partner Jose"} position={'quote__container--left'} className={'quote--grey'} backgroundImage={true}>
			<p className='quote-text'>"Sometimes knowing that you are not alone helps you cope with what you're going through."</p>
		</Quote>
		<section className='faq cta-row cta-row--white'>
			<div className="wrapper">
				<h2>Frequently asked questions</h2>
				<div className="faq__content">
					<h3>Will I be able to afford treatment with LUPKYNIS?</h3>
					<p>Everyone's situation is different, but when you enroll with Aurinia Alliance, your Nurse Case Manager will partner with you and walk through all the options available to you based on your individual coverage.</p>
					<p>Aurinia also offers a copay program for eligible commercial patients. $0 copay for eligible commercial patients.</p>
					<p><strong>97% of the time*, patients paid less than $10 for their LUPKYNIS prescription.</strong></p>
				</div>
				<div className="faq__content">
					<h3>Who do I talk to if I have questions about my treatment?</h3>
					<p>If you have questions specifically about LUPKYNIS or any other aspects of your lupus nephritis treatment, you should always talk to your doctor. When you enroll in Aurinia Alliance, you'll be connected with a dedicated Nurse Case Manager who will provide the consistency and expertise needed to help you navigate your LUPKYNIS treatment journey. <Link to='/enroll' className='link blue-cta'>Sign up here</Link> to get started. You can also enroll by calling <span className="nowrap">1-833-AURINIA (1-833-287-4642)</span>.</p>
				</div>
				<Anchor linkClass="btn btn--purple" link="https://www.lupkynis.com/resources#FAQ" isExternal={true} requiresExitModal={true} relationship="noopener noreferrer">SEE MORE FAQS</Anchor>
			</div>
		</section>

		<StickyTray buttonText={''} addedClass={'sticky-tray'} targetId="#ISI">
			<div className="isi__content">
				<ISIHead></ISIHead>
			</div>
		</StickyTray>
		<ISI></ISI>

	</Layout>
	)
};

export default ProgramResources;

export const pageQuery = graphql`
  query ProgramResourcesHeroQuery {
    heroSmall: file(relativePath: {eq: "hero/resources-hero--s.png"}) {
		childImageSharp {
		  gatsbyImageData(width:375)
		}
	},
	heroMedium: file(relativePath: {eq: "hero/resources-hero--m.png"}) {
		childImageSharp {
		  gatsbyImageData(width:1440)
		}
	},	
	heroLarge: file(relativePath: {eq: "hero/resources-hero--l.png"}) {
		childImageSharp {
		  gatsbyImageData(width:1500)
		}
	},
	signature: file(relativePath: {eq: "hero/resources-signature.png"}) {
		childImageSharp {
		  gatsbyImageData(width:134)
		}
	},
	tiffanyPortrait: file(relativePath: {eq: "portrait/tiffany.png"}) {
		childImageSharp {
		  gatsbyImageData(width:200)
		}
	},
	nancyPortrait: file(relativePath: {eq: "portrait/nancy.png"}) {
		childImageSharp {
		  gatsbyImageData(width:200)
		}
	},
}
`
